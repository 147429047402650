import {Pipe,PipeTransform} from "@angular/core"; 
@Pipe({
  name: 'noImage'
})

export class NoImagePipe implements PipeTransform {
  transform(images?: string):string {
    if(!images) {
    	return './../../../../assets/icons/recurso_13.png';
    }else{
      return images;
    }
  }
}