import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AlertsService } from '../services/alerts.service';
import { MyAuthService } from '../services/myauth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(private myAuthService: MyAuthService, private router: Router) { }

  canActivate() {


    if (this.myAuthService.isLoggedIn()) {
      this.router.navigate(['admin/home']);
    }
    return !this.myAuthService.isLoggedIn();

  
  }

  
}
