import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, CanLoad, Router } from "@angular/router";
import { AlertsService } from "../services/alerts.service";
import { MyAuthService } from "../services/myauth.service";

@Injectable({
  providedIn: "root"
})
export class RandomGuard implements CanActivate, CanLoad {
  
  token: string = localStorage.getItem("access_token");
  constructor(private myAuthService: MyAuthService, private router: Router, private alertService: AlertsService) {}

  canActivate() {
    return this.canLoad();
  }

  canLoad() {

    // console.log('ENTRA CAM')
    // this.myAuthService.getAuthUser(this.token).subscribe(
    //   (data: any) => {
    //     // this.user = data;
    //     // this.pagination.idUser = data.id;
    //     // this.formatted_address = this.user.direccion;
    //     // console.log('ESTA ES LA DIRECCION: ',this.user.direccion)
    //    // || this.user.status_de_pago.descripcion == 'Pagado'
    //        if(data.status_de_pago.descripcion == 'Moroso'){

    //         this.alertService.msg("ERR", "Debe realizar su pago pendiente para continuar disfrutando del servicio¡");
    //       //   if(data.status_de_pago.descripcion == 'Moroso'){
            
    //       // }

    //       // if(data.direccion == null){
    //       //   this.alertService.msg("INFO", "Debe continuar con el registro de sus datos!");
    //       // }
            
    //       this.router.navigate(["admin/profile"]);
    //           }

    //            if(data.direccion == null){
    //          this.alertService.msg("INFO", "Debe continuar con el registro de sus datos!");
    //          this.router.navigate(["admin/profile"]);
    //        }
        
    //   },
    //   (error) => {
    //     //console.log(error);
    //     // this.removeLocalStorageItems();
    //     // this.router.navigate(["/auth/login"]);
    //     // if (typeof error === "string") {
    //     //   this.alertService.msg("ERR", error);
    //     // }
    //   }
    // );





    if (!this.myAuthService.isLoggedIn()) {
      this.router.navigate(["/auth/login"]);
    }
    return this.myAuthService.isLoggedIn();
  }
}
