import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
// modules
import { RouterModule } from "@angular/router";
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { PipeModule } from "./admin/components/pipes/pipe.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AppRoutingModule } from "./app-routing.module";
//components
import { AppComponent } from "./app.component";
import { NopagefoundComponent } from "./components/nopagefound/nopagefound.component";
// import { blogComponent } from "./admin/blog/blog/blog.component";

// services
import { AuthGuard } from "./services/auth.guard";
import { ErrorInterceptor } from "./services/interceptors";


@NgModule({
  declarations: [AppComponent, NopagefoundComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    HttpClientModule,
    PipeModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    SelectDropDownModule,
    NgbTooltipModule,
    AppRoutingModule
  ],
  exports: [NgbModule, PipeModule, NgMultiSelectDropDownModule],
  bootstrap: [AppComponent]
})

export class AppModule {}