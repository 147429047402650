import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoImagePipe} from "./noImage.pipe";


@NgModule({
  declarations: [ NoImagePipe],
  imports: [ ],
  providers:[  ],
  exports:[NoImagePipe]
})
export class PipeModule { 
	static forRoot() {
	      return {
	          ngModule: PipeModule,
	          providers: [],
	      };
	}
}

