import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from 'rxjs';
@Injectable({
  providedIn: "root"
})
export class MyAuthService {
  token: any = localStorage.getItem("access_token");
  dataUser: any;
  constructor(private http: HttpClient) {}
  login(data: any) {
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/v1/login"
        : environment.apiHost + "/api/v1/login",
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }
    );
  }
  
  getNotificationsByUser(user:any){    
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/auth/getNotiPorAuthUser"
        : environment.apiHost + "/api/auth/getNotiPorAuthUser",
        user,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }
  getAuthUser(access_token) {
    return this.http.get(
      environment.production
        ? environment.apiHost + "/api/auth/getUser"
        : environment.apiHost + "/api/auth/getUser",
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + access_token
        })
      }
    );
  }
  isLoggedIn() {
    return !!this.getJwtToken();
  }
  getJwtToken() {
    return localStorage.getItem("access_token");
  }
  logout() {
    return this.http.get(
      environment.production
        ? environment.apiHost + "/api/auth/logout"
        : environment.apiHost + "/api/auth/logout",
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }
  signUp(data: any) {
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/v1/crearUser"
        : environment.apiHost + "/api/v1/crearUser",
      data
    );
  }
  update(id: any, data: any) {
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/auth/editarUser/" + id
        : environment.apiHost + "/api/auth/editarUser/" + id,
      data,
      {
        headers: new HttpHeaders({
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }
  recoverPassword(email) {
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/v1/reestablecerClave"
        : environment.apiHost + "/api/v1/reestablecerClave",
      { email },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        })
      }
    );
  }
  deleteAccount(id: any) {
    return this.http.delete(
      environment.production
        ? environment.apiHost + "/api/auth/borrarUser/" + id
        : environment.apiHost + "/api/auth/borrarUser/" + id,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }
  getGeolocation() {
    return this.http.post(
      "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCkxMPfhbQst5Q-rLZj4NLTxLgcorXJtQg",
      { data: "" },
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }
    );
  }
  setTimeUser(data: any) {
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/auth/setTimeUser"
        : environment.apiHost + "/api/auth/setTimeUser",
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }
  choosePaymentPlan(data: any) {
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/auth/setPlanDePago"
        : environment.apiHost + "/api/auth/setPlanDePago",
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }
  //CONFIRMAR LE ENTREGA POR PARTE DUEÑO
  toConfirm(data: any){
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/auth/confirmarEntrega"
        : environment.apiHost + "/api/auth/confirmarEntrega",
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }
  //CONFIRMAR LE ENTREGA POR PARTE DEL SOLICITANTE
  toConfirmClient(data: any){
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/auth/confirmarEntregaCliente"
        : environment.apiHost + "/api/auth/confirmarEntregaCliente",
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }

  toConfirmClient2(data: any){
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/auth/calificarUser"
        : environment.apiHost + "/api/auth/calificarUser",
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }

  toRefuse(data: any){
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/auth/rechazarEntrega"
        : environment.apiHost + "/api/auth/rechazarEntrega",
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }
  toRefuseGeneral(data: any){
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/auth/rechazarEntregaGeneral"
        : environment.apiHost + "/api/auth/rechazarEntregaGeneral",
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        })
      }
    );
  }
  readNotification(idNotificacion:number){
    console.log('pasaNOTIFICACION: '+ idNotificacion)
      return this.http.get(
        environment.production
          ? environment.apiHost + "/api/auth/leerNotificacion/"+idNotificacion
          : environment.apiHost + "/api/auth/leerNotificacion/"+idNotificacion,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token")
          })
        }
      );
  }
  readManualNotification(idNotificacion:number){
      return this.http.get(
        environment.production
          ? environment.apiHost + "/api/auth/notificacion/user/cambiarStatus/"+idNotificacion
          : environment.apiHost + "/api/auth/notificacion/user/cambiarStatus/"+idNotificacion,
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token")
          })
        }
      );
  }

  logininvitado(): Observable<any> {
    // Definir usuario y contraseña fijos
    const email = 'invitadoslibreando@gmail.com';
    const password = 'cxa3LJTor1fya4hm9X5W';

    // Crear objeto de datos con el usuario y contraseña
    const data = { email: email, password: password };

    // Realizar la solicitud de inicio de sesión al backend
    return this.http.post(
      environment.production
        ? environment.apiHost + "/api/v1/login"
        : environment.apiHost + "/api/v1/login",
      data,
      {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }
    );
  }
}
