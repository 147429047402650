import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { RandomGuard } from "./guards/random.guard";
import { NopagefoundComponent } from "./components/nopagefound/nopagefound.component";
import { EventsGuard } from "./guards/events.guard";
import { NavheaderComponent } from "./admin/components/navheader/navheader.component";
import { LoginComponent } from "./auth/components/login/login.component";

const routes: Routes = [
  {
    path: "guest",
    loadChildren: "./guest/guest.module#GuestModule"
  },
  { path: "guest", redirectTo: "guest", pathMatch: "full" },
  {
    path: "admin",
    canActivate: [RandomGuard],
    canLoad: [RandomGuard],
    //canActivateChild: [RandomGuard],
    loadChildren: "./admin/admin.module#AdminModule"
  },
  { path: "login/:token", redirectTo: "login/:token", pathMatch: "full" },
  {
    path: "auth",
    canActivate: [AuthGuard],
    loadChildren: "./auth/auth.module#AuthModule"
  },
  { path: "", redirectTo: "auth/login", pathMatch: "full" },
  { path: "**", component: NopagefoundComponent },
  // { path: 'navheader', component: NavheaderComponent },
  // { path: 'login', component: LoginComponent },
  { path: 'login/:resgistrate', redirectTo: "auth/login/:resgistrate", }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true, // <-- debugging purposes only
      preloadingStrategy: PreloadAllModules,
      useHash: true
      
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}